/*
 * @Author: shuo
 * @Date: 2019-06-25 23:13:22
 * @Last Modified by: shuo
 * @Last Modified time: 2021-06-16 11:29:45
 */
import aTagMap from './config/aTagMap'
import bTagMap from './config/bTagMap'
import cTagMap from './config/cTagMap'

let onloadTag = false

if (typeof gbCommonInfo !== 'undefined') {
  const { PUBLIC_CDN } = gbCommonInfo
  __webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`
}

if (typeof window !== 'undefined' && typeof performance !== 'undefined') {
  if (performance.timing.loadEventStart > 0) {
    onloadTag = true
  } else {
    window.addEventListener('load', () => {
      onloadTag = true
      analysisRequire.executeSubscribeRequireInQueue()
    })
  }
}

/**
 * modulecode meaning
 * A: 类型
 *  1 页面 pages
 *  2 组件 components
 *
 * B：页面Id / 共用组件Id （累加）
 *  统一映射表维护
 *  ex：1 首页
 *
 * C：模块Id （累加），除首次加载当前页面的埋点信息外（1），还可以定义需按需加载的埋点信息
 *
 * D：上报Id （累加）
 *
 * 页面定义格式 A-B-C-D
 * 组件定义格式 A-B-D
*/
const pathPrefix = './config'
const importFile = async ({ modulecode = '' } = {}) => {
  if (!modulecode || typeof modulecode !== 'string') return Promise.resolve()
  const splitCode = modulecode.split('-') || []
  const path = [pathPrefix]
  try {
    // check A tag map
    const aTag = splitCode[0] || '0'
    if (!aTagMap[aTag]) throw new Error(`aTag: ${aTag} undefined from aTagMap: ${JSON.stringify(aTagMap)}`)
    path.push(aTagMap[aTag])

    // check B tag map
    const bTag = splitCode[1] || '0'
    if (!bTagMap[`${aTag}-${bTag}`]) throw new Error(`bTag: ${`${aTag}-${bTag}`} undefined from bTagMap: ${JSON.stringify(bTagMap)}`)
    path.push(bTagMap[`${aTag}-${bTag}`])

    if (aTag === '1') {
      const cTag = splitCode[2] || '0'
      if (!cTagMap[`${aTag}-${bTag}-${cTag}`]) throw new Error(`cTag: ${`${aTag}-${bTag}-${cTag}`} undefined from cTabMap: ${JSON.stringify(cTagMap)}`)
      path.push(cTagMap[`${aTag}-${bTag}-${cTag}`])
    }
    return await import(/* webpackChunkName: "event-center-" */ /* @vite-ignore */ `${path.join('/')}`)
  } catch (e) {
    console.error(e)
    return Promise.resolve()
  }
}

let analysisRequire = {
  subscribeLoadQueue: [],
  executeSubscribeRequireInQueue() {
    while(this.subscribeLoadQueue.length > 0) {
      let { resolve, modulecode } = this.subscribeLoadQueue.shift()
      resolve(importFile({ modulecode }))
    }
  },
  require ({ modulecode = null } = {}) {
    if (!modulecode) return
    if (!onloadTag) {
      return new Promise((resolve, reject) => {
        this.subscribeLoadQueue.push({ resolve, reject, modulecode })
      })
    }
    return importFile({ modulecode })
  }
}

export default analysisRequire
