/*
 * event center
 * @Author: shuo 
 * @Date: 2019-06-25 22:58:22 
 * @Last Modified by: shuo
 * @Last Modified time: 2021-06-09 16:20:48
 */
import controleCenter from './control-center'
import publisher from './publisher'
import subscriber from './subscriber'

import analysisReport from './analysis/report'

import eventIndex from './event/index'
import DaExpose from './event/expose.js'

class eventCenter {
  constructor () {
    // create channel control center
    this.controlCenter = new controleCenter()

    // create publisher
    this.publisher = new publisher({ controlCenter: this.controlCenter })
        
    // create subscribe
    this.subscriber = new subscriber({ controlCenter: this.controlCenter })

    this.event = new eventIndex({ 
      controlCenter: this.controlCenter, 
      publisher: this.publisher, 
      subscriber: this.subscriber
    })

    // 页面曝光 code 收集,eg: { pathname: new Set() }
    this.exposeCodeMap = new Map()
    // scroll Event
    this.scrollInstance = this.getExposeInstance()
  }

  /** 
     * 对外暴露绑定观察者
    */
  addSubscriber ({ modulecode = '' } = {}) {
    if (!modulecode) return
    this.subscriber.subscribe({ modulecode })
  }

  /** 
     * 对外暴露 手动触发通知事件
    */
  triggerNotice ({ target = '', daIdTag = '', daId = '', bindData = null, extraData = {}, delay = 0 } = {}) {
    if (!daId) return
    // 没有传入dom
    if (daIdTag && !target) {
      const item = $(`[${daIdTag}="${daId}"]`)
      target = item && item.length > 0 && item[0]
    }

    const notify = () => this.publisher.notify({
      target,
      daId,
      sendType: 'user',
      extraData,
      bindData,
    })

    if (delay > 0) {
      if (window.requestIdleCallback) {
        window.requestIdleCallback(notify, { timeout: delay })
      } else {
        setTimeout(notify, delay)
      }
    } else {
      notify()
    }
  }
    
  /** 
     * 对外暴露 上报方法
    */
  reportHandler ({ sa = null } = {}) {
    analysisReport.report({ sa })
  }

  /** 
     * 对外暴露 事件绑定方法
     * fix：事件冒泡被禁止的事件源
    */
  bindEvent ({ event = '', daId = '' } = {}) {
    if (!event || !daId) return
    this.event.bindEventByDaId({
      event,
      daId
    })
  }

  recordExposeCode ({ code } = {}) {
    const currentPath = location.pathname
    if (this.exposeCodeMap?.has?.(currentPath)) {
      const uniqueCodes = this.exposeCodeMap.get(currentPath)
      uniqueCodes.add(code)
    } else {
      this.exposeCodeMap?.set?.(currentPath, new Set([code]))
    }
  }

  getExposeInstance(config) {
    // 如果没有配置，且自身有曝光实例
    if (!config && this.scrollInstance) return this.scrollInstance
    return new DaExpose({ publisher: this.publisher, recordExposeCode: this.recordExposeCode.bind(this), ...config })
  }
}

function getEventCenter () {
  if (typeof window === 'undefined') return {
    addSubscriber(){},
    triggerNotice(){},
    reportHandler(){},
    bindEvent(){},
    getExposeInstance(){},
    recordExposeCode(){},
  }
  if (window.daEventCenter) return window.daEventCenter

  const daEventCenter = window.daEventCenter = new eventCenter()
  return daEventCenter
}

const daEventCenter = getEventCenter()

export {
  daEventCenter
}
